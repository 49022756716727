import * as React from "react";
import Header from "../components/header";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/400.css";
import "../styles/content.scss";
import "../styles/faq.scss";
import Footer from "../components/footer";
import Seo from "../components/helmet";

const expandAnswer = (actEl: any, id: number, isParent?: boolean) => {
  try {
    if (isParent) {
      const qel = document.getElementById("q" + id);
      if (qel) {
        qel.setAttribute(
          "aria-pressed",
          !(qel.getAttribute("aria-pressed") === "true") + ""
        );
      }
    } else {
      actEl.parentNode.setAttribute(
        "aria-pressed",
        !(actEl.getAttribute("aria-pressed") === "true")
      );
    }

    actEl.setAttribute(
      "aria-pressed",
      !(actEl.getAttribute("aria-pressed") === "true")
    );

    const ael = document.getElementById("a" + id);
    if (ael) {
      ael.setAttribute(
        "aria-expanded",
        !(ael.getAttribute("aria-expanded") === "true") + ""
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const FaqPage = () => {
  return (
    <React.Fragment>
      <Seo title="FAQ - Flock Finance"></Seo>
      <Header></Header>

      <title>FAQ</title>

      <h1 className="title-banner">FAQ</h1>

      <div className="faq">
        <div className="content">
          <div className="questions">
            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 1, true)
              }
            >
              <div
                id="q1"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 1)}
              >
                <div className="text">WHAT IS INVOICE FINANCE?</div>
                <div className="icon">+</div>
              </div>
              <div id="a1" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 2, true)
              }
            >
              <div
                id="q2"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 2)}
              >
                <div className="text">
                  IS THERE A MINIMUM AMOUNT I NEED TO INVEST?
                </div>
                <div className="icon">+</div>
              </div>
              <div id="a2" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 3, true)
              }
            >
              <div
                id="q3"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 3)}
              >
                <div className="text">CAN I CONTACT A SELLER?</div>
                <div className="icon">+</div>
              </div>
              <div id="a3" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 4, true)
              }
            >
              <div
                id="q4"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 4)}
              >
                <div className="text">WHAT ARE FLOCK’S FEES?</div>
                <div className="icon">+</div>
              </div>
              <div id="a4" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 5, true)
              }
            >
              <div
                id="q5"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 5)}
              >
                <div className="text">HOW DO I ADD MONEY TO MY WALLET?</div>
                <div className="icon">+</div>
              </div>
              <div id="a5" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 6, true)
              }
            >
              <div
                id="q6"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 6)}
              >
                <div className="text">
                  WHAT HAPPENS IF AN INVOICE ISN’T FULFILLED?
                </div>
                <div className="icon">+</div>
              </div>
              <div id="a6" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>

            <div
              className="question"
              tabIndex={0}
              onKeyDown={(event) =>
                event.keyCode != 13 || expandAnswer(this, 7, true)
              }
            >
              <div
                id="q7"
                className="heading"
                role="button"
                onClick={(event) => expandAnswer(event.currentTarget, 7)}
              >
                <div className="text">HOW DO REFERRALS WORK?</div>
                <div className="icon">+</div>
              </div>
              <div id="a7" className="answer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum
                suspendisse ultrices gravida. Risus commodo viverra maecenas
                accumsan lacus vel facilisis.
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </React.Fragment>
  );
};

export default FaqPage;
